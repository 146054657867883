/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PuzzleGame } from "./PuzzleGame";

export function debugLog(...message: any): void {
	console.log(...message);
}

document.addEventListener("DOMContentLoaded", () => {
	// eslint-disable-next-line no-new
	new PuzzleGame();
});
