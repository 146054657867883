
export interface IImage {
  filename: string;
  object?: HTMLImageElement;
  loaded: boolean;
  error?: boolean;
  errorCode?: string;
}

export class PreLoader {

  private path: string;
  private antiCache: boolean;
  private imageList: Array<IImage> = [];
  private images: Array<HTMLImageElement> = [];
  private readyCount = 0;
  private cb: CallableFunction;

  public setPath(path: string, antiCache = false): void {
    this.path = path;
    this.antiCache = antiCache;
  }

  public preload(list: Array<string>, cb?: CallableFunction): void {
    this.cb = cb;
    list.forEach(imgname => {
      this.addImage(imgname);
    });
  }

  private addImage(name: string) {
    if (this.getimage(name)) {
      return;
    }

    const image: IImage = {
      filename: name,
      object: new Image(),
      loaded: false
    };

    image.object.src = this.path + '/' + name + (this.antiCache ? '?' + Date.now() : '');
    image.object.addEventListener('load', () => this.onload(image));
    image.object.addEventListener('error', (e) => this.onloadError(e, image));
    this.imageList.push(image);
  }

  private onload(image: IImage): void {
    image.loaded = true;
    this.readyCount++;
    this.check();
  }

  getimage(name: string): IImage | null  {
    return this.imageList.find(i => i.filename === name) || null;
  }

  private onloadError(e: ErrorEvent, image: IImage) {
    console.error('image', this.path + '/' + image.filename, 'not found');
    image.error = true;
    this.readyCount++;
    this.check();
  }

  private check() {
    if (this.readyCount === this.imageList.length) {
      console.log('all images are loaded');
      if (this.cb) {
        this.cb();
      }
    }
  }
}
