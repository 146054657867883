/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
"use strict";

import { disableTouch, setEvent, SetDragEvent } from "./Platform";
import { Game, GamePlatform } from "./GamePlatform";
import { Layer } from "./Layers";
import { Tile } from "./Tile";
import { GameCanvas } from "./GameCanvas";
import { Configurator } from "./Configurator";
import { PreLoader } from "./PreLoader";
import { DragHandler } from "./DragHandler";
import {
  GameState,
  IActionButton,
  IGameConfig,
  IGameSizes,
  IGameState,
  ILevel,
  IPosition,
} from "./interfaces";
import { Howl } from "howler";

interface Rect {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export class PuzzleGame implements Game {
  private ELMcanvas: HTMLCanvasElement = <HTMLCanvasElement>(
    document.getElementById("myCanvas")
  );
  private ELMScoreContainer = document.getElementById("score");

  public platform: GamePlatform;
  private preloader: PreLoader;
  private configurator: Configurator;

  private debug = false;

  private layers$: Array<Layer> = [];
  private tiles$: Array<Tile> = [];
  private levels$: Array<ILevel> = [];
  private state$: IGameState = {
    gameState: "init",
    score: 0,
    dragging: false,
    startTime: new Date(),
    activeLevel: 0,
    progress: 0,
    level: null,
    displayTime: "",
    paused: false,
    pauseTime: null,
    showBars: false,
    resultsend: false,
  };

  private config$: IGameConfig;
  private canvas$: GameCanvas;
  private dragLayer: Layer;
  private dragCorrection: IPosition = { x: 0, y: 0 };
  private sizes$: IGameSizes;

  get config() {
    return this.config$;
  }

  get sizes(): IGameSizes {
    return this.sizes$;
  }

  get state() {
    return this.state$;
  }

  get layers() {
    return this.layers$;
  }

  constructor() {
    if (window.location.search.search("debug") !== -1) {
      this.debug = true;
    }
    // Load utilities
    this.canvas$ = new GameCanvas(this, this.ELMcanvas);
    this.preloader = new PreLoader();
    this.configurator = new Configurator();
    this.platform = new GamePlatform();

    disableTouch(); // Prevent zoom etc.

    this.loadConfig();

    // Preload images and call init afterwards. Requires images to be loadeds
    this.preloadImages(this.init.bind(this));
    this.initToolbar();
    this.platform.preloadSounds(this.config);
  }

  // Receive play message from platform
  public play() {
    this.preview();
  }

  // Retrieve a tile based on row and column
  public getTile(row: number, col: number): Tile {
    return this.tiles$.find((tile) => {
      return tile.row === row && tile.col === col;
    });
  }

  // get the active image
  public getImage() {
    return this.preloader.getimage(this.state$.level.image).object;
  }

  public removeLayer(dragLayer: Layer): void {
    for (let i = 0; i < this.layers$.length; i++) {
      if (this.layers$[i] === dragLayer) {
        this.layers$.splice(i, 1);
        i--;
        return;
      }
    }
  }

  private loadConfig() {
    this.config$ = this.configurator.loadConfig();
    this.levels$ = [];
    if (this.config$.levels) {
      this.config$.levels.split(";").forEach((ld) => {
        const parts = ld
          .trim()
          .split(",")
          .map((p) => p.trim());
        if (parts.length !== 3) {
          throw Error("Invalid level" + ld);
        }
        const sizeParts = parts[1]
          .trim()
          .split("x")
          .map((p) => p.trim());
        if (sizeParts.length !== 2) {
          throw Error("Invalid level" + ld);
        }

        this.levels$.push({
          image: parts[0],
          rows: +sizeParts[0],
          cols: +sizeParts[1],
          duration: +parts[2] / 1000,
        } as ILevel);
      });
    }
  }

  // Preload images and call callback on success
  private preloadImages(cb: CallableFunction) {
    this.preloader.setPath(this.config$.path);
    const imagelist = this.levels$.map((l) => l.image);
    this.preloader.preload(imagelist, cb);
  }

  // Called after preloader
  private init() {
    console.warn("INIT");
    this.patchCss();

    this.canvas$.init();

    // Basic state
    this.resetState();

    // Do not allow events before
    this.registerEvents();

    // Load first game
    // Load next level
    this.loadlevel();

    // Start loop

    setTimeout(() => {
      this.platform.init(this, this.config$, [
        "btnFont",
        "popupFont",
        "timeBoxFont",
        "scoreFont",
      ])
    }, 10)
    
    this.nextFrame();


    // Prepare first game
    this.prepareGame();
  }

  private resetState() {
    console.log("initState");
    // Load the inital state of a game
    this.state$ = {
      gameState: "ready",
      score: 0,
      dragging: false,
      startTime: new Date(),
      activeLevel: 0,
      pauseTime: null,
      paused: false,
      displayTime: "",
      progress: 100,
      resultsend: false,
      showBars: false,
    } as IGameState;

    this.closePopup();
    document.getElementById("buttonBar").classList.remove("open2");
    document.getElementById("buttonBar").classList.remove("open");
    (<HTMLElement>document.getElementById("myBar")).style.width = "100%";
  }

  private handleResize() {
    // recalculate sizes
    this.initSizes();
    this.layers$.forEach((l) => {
      l.updateSizes();
    });
  }

  private initSizes() {
    // Scale canvas content to full size of element
    this.ELMcanvas.width = this.ELMcanvas.offsetWidth;
    this.ELMcanvas.height = this.ELMcanvas.offsetHeight;

    const canvasSize = {
      width: this.ELMcanvas.offsetWidth,
      height: this.ELMcanvas.offsetHeight,
    };

    const imageSize = {
      width: this.getImage().width,
      height: this.getImage().height,
    };

    const imageRatio = imageSize.width / imageSize.height;
    const maxPuzzleSize = {
      width: canvasSize.width * (this.config$.puzzleSize / 100),
      height: canvasSize.height * (this.config$.puzzleSize / 100),
    };
    const newHeight = maxPuzzleSize.width / imageRatio;
    const puzzleSize =
      newHeight > maxPuzzleSize.height // Too large
        ? {
            width: maxPuzzleSize.height * imageRatio,
            height: maxPuzzleSize.height,
          }
        : {
            width: maxPuzzleSize.width,
            height: maxPuzzleSize.width / imageRatio,
          };

    // Read real size of canvas element
    const sourceTileSize = {
      width: this.getImage().width / this.state.level.cols,
      height: this.getImage().height / this.state.level.rows,
    };

    const tileSize = {
      width:
        (puzzleSize.width - this.config$.puzzleBoxPadding * 2) /
        this.state.level.cols,
      height:
        (puzzleSize.height - this.config$.puzzleBoxPadding * 2) /
        this.state.level.rows,
    };

    this.sizes$ = {
      canvas: canvasSize,
      puzzle: puzzleSize,
      tile: tileSize,
      sourceTile: sourceTileSize,
    };
  }

  // Create gameCanvas object and add all layers and tile
  private prepareGame() {
    this.resetState();
    this.loadlevel();

    // When state is ready, start drawing;
    // this.setGameState('ready');

    // Send message to platform we are ready to receive play events
    // this.pew.init(this, () => {
    //   this.pew.ready();
    // });
  }

  private loadlevel() {
    this.closePopup();
    this.state$.level = this.levels$[this.state$.activeLevel];

    this.state$.progress = 100;
    this.state$.displayTime = "0:00";

    // Change state
    this.state$.startTime = new Date();
    this.state$.showBars = false;
    this.initSizes();

    this.layers$ = [];
    this.tiles$ = this.createTiles();
    this.tiles$.forEach((tile) => {
      this.layers$.push(new Layer(tile, this));
    });
  }

  private createTiles(): Array<Tile> {
    const result: Array<Tile> = [];
    for (let row = 0; row < this.state.level.rows; row++) {
      for (let col = 0; col < this.state.level.cols; col++) {
        result.push(new Tile(this, col + 1, row + 1));
      }
    }
    return result;
  }

  private showPopup(message: string, buttons: IActionButton[]) {
    const buttonBar = document.getElementById("popupButtons");
    while (buttonBar.firstChild) {
      buttonBar.removeChild(buttonBar.lastChild);
    }

    buttons.forEach((b) => {
      const button = document.createElement("div");
      button.classList.add("btn");
      button.addEventListener("click", () => {
        this.playSound("btnClick");
      });
      setEvent(button, b.event);
      button.innerHTML = b.caption;
      buttonBar.appendChild(button);
    });

    document.getElementById("message").innerHTML = message;
    document.getElementById("popup").style.display = "block";
  }
  private closePopup() {
    document.getElementById("popup").style.display = "none";
  }

  // Go to next level and show preview
  private nextLevel() {
    this.state$.activeLevel++;
    this.loadlevel();
    // (<HTMLElement>document.getElementById("myProgress")).style.visibility =
    //   "hidden";
    this.platform.sendTimeleft(100, true);
    this.preview();
  }

  private addLayerScore() {
    this.state$.score += this.config$.pointsPerTile;
    this.updateScore();
  }

  private dragStart(mouseX: number, mouseY: number) {
    if (this.state$.paused) {
      return this.resume();
    }

    const rect = this.ELMcanvas.getBoundingClientRect();
    const gameX = mouseX - rect.left;
    const gameY = mouseY - rect.top;
    // If ready, start timer on first down event
    // if (this.state$.gameState === 'ready') {
    //   // this.setGameState("preview");
    //   this.start();
    //   return;
    // }

    // If not playing, no further events
    if (this.state$.gameState !== "playing") {
      if (this.dragLayer) {
        this.dragLayer.setZoom(false);
      }
      this.dragLayer = null;
      this.state$.dragging = false;
      this.dragCorrection = { x: 0, y: 0 };
      return;
    }

    // Find object
    this.dragLayer = this.canvas$.checkCoordinates({ x: gameX, y: gameY });

    //
    if (!this.dragLayer) {
      return;
    }

    // A  layer is found on the coordinates.
    // Start dragging
    this.state$.dragging = true;
    this.dragLayer.setZoom(true);
    this.playSound("pickUp");
    // Move layer to top
    for (let i = 0; i < this.layers$.length; i++) {
      if (this.layers$[i] === this.dragLayer) {
        this.layers$.splice(i, 1);
        this.layers$.push(this.dragLayer);
      }
    }

    // Register correctiong for dragging
    this.dragCorrection = {
      x: gameX - this.dragLayer.getPosition().x,
      y: gameY - this.dragLayer.getPosition().y,
    };
  }

  private dragMove(mouseX: number, mouseY: number) {
    if (!this.state$.dragging) {
      return;
    }
    if (!this.dragLayer) {
      this.state$.dragging = false;
      return;
    }

    const rect = this.ELMcanvas.getBoundingClientRect();
    const gameX = mouseX - rect.left;
    const gameY = mouseY - rect.top;
    this.dragLayer.setPosition(
      {
        x: gameX - this.dragCorrection.x,
        y: gameY - this.dragCorrection.y,
      },
      false
    );
  }

  private dragEnd() {
    this.state$.dragging = false;
    if (!this.dragLayer) {
      return;
    }

    const snaplayer = this.dragLayer.trySnap();

    // If a layer to snap is found, Merge the draglayer
    if (snaplayer) {
      this.playSound("snap");
      snaplayer.mergeLayer(this.dragLayer);
      this.addLayerScore(); // Receive points
      snaplayer.checkPosition(this.config$.mergeBounceTime); // Check if the position of the merged layer is outside canvas.
    } else {
      this.playSound("release");
      this.dragLayer.checkPosition(this.config$.bounceTime); // Check position of the drag layer if not snapped. Animate back to canvas
    }

    // Remove zoom from the draglayer
    this.dragLayer.setZoom(false);
    this.dragLayer = null;
  }

  private checkWinCondition() {
    return this.layers$.length === 1;
  }

  private registerEvents() {
    new DragHandler(
      this.ELMcanvas,
      this.dragStart.bind(this),
      this.dragMove.bind(this),
      this.dragEnd.bind(this)
    );

    window.addEventListener("resize", () => this.handleResize());
    document.addEventListener(
      "blur",
      () => {
        this.showPause();
        // Pause on blur?
      },
      { passive: false }
    );

    window.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "hidden") {
          this.showPause();
        }
      },
      { passive: false }
    );

    // Safari
    document.addEventListener(
      "pagehide",
      () => {
        this.showPause();
      },
      { passive: false }
    );
  }

  private pause() {
    // Set pausetime flag.
    if (!this.state$.paused) {
      this.state$.paused = true;
      this.state$.pauseTime = new Date();
    }
  }

  private resume() {
    if (this.state$.paused) {
      this.state$.startTime.setTime(
        this.state$.startTime.getTime() +
          (new Date().getTime() - this.state$.pauseTime.getTime())
      );
      this.state$.pauseTime = null;
      this.state$.paused = false;
      this.closePopup();
    }
  }

  private shuffleChaotic() {
    let randomX;
    let randomY;
    const max: IPosition = {
      x:
        this.sizes$.canvas.width -
        this.sizes$.tile.width -
        this.config$.horizontalMargin,
      y:
        this.sizes$.canvas.height -
        this.sizes$.tile.height -
        this.config$.verticalMargin,
    };

    this.layers$.sort(this.compareLayers);
    this.layers$.reverse();
    this.layers$.forEach((layer) => {
      if (layer.getTileCount() <= 1) {
        randomX = this.getRandomNumber(this.config$.horizontalMargin, max.x);
        randomY = this.getRandomNumber(this.config$.verticalMargin, max.y);
        layer.setPosition(
          { x: randomX, y: randomY },
          true,
          this.config$.shuffleTime
        );
      }
    });
  }

  private shuffleOrdered() {
    let randomX: number;
    let randomY: number;

    const center = {
      x: this.sizes$.canvas.width / 2 - this.sizes$.tile.width / 2,
      y: this.sizes$.canvas.height / 2 - this.sizes$.tile.height / 2,
    };

    this.layers$.sort(this.compareLayers);
    this.layers$.reverse();
    this.layers$.forEach((layer) => {
      if (layer.getTileCount() <= 1) {
        let position = this.getRandomPosition();

        console.log(position);

        switch (position) {
          case "top":
            randomX =
              center.x +
              this.getRandomNumber(
                -this.sizes$.puzzle.width / 2,
                this.sizes$.puzzle.width / 2
              );
            randomY =
              center.y -
              this.sizes$.puzzle.height / 2 -
              this.sizes$.tile.height / 2 -
              5;
            break;
          case "bottom":
            randomX =
              center.x +
              this.getRandomNumber(
                -this.sizes$.puzzle.width / 2,
                this.sizes$.puzzle.width / 2
              );
            (randomY =
              center.y +
              this.sizes$.puzzle.height / 2 +
              this.sizes$.tile.height / 2 +
              5),
              this.sizes$.canvas.height -
                this.config$.verticalMargin -
                this.sizes$.tile.height;
            break;
          case "left":
            randomX =
              center.x -
              this.sizes$.puzzle.width / 2 -
              this.sizes$.tile.width / 2 -
              5;
            randomY =
              center.y +
              this.getRandomNumber(
                -this.sizes$.puzzle.height / 2,
                this.sizes$.puzzle.height / 2
              );
            break;
          case "right":
            randomX =
              center.x +
              this.sizes$.puzzle.width / 2 +
              this.sizes$.tile.width / 2 +
              5;
            randomY =
              center.y +
              this.getRandomNumber(
                -this.sizes$.puzzle.height / 2,
                this.sizes$.puzzle.height / 2
              );
            break;
        }

        randomX = Math.max(randomX, this.config$.horizontalMargin);
        randomX = Math.min(
          randomX,
          this.sizes$.canvas.width -
            this.sizes$.tile.width -
            this.config$.horizontalMargin
        );

        randomY = Math.max(randomY, this.config$.verticalMargin);
        randomY = Math.min(
          randomY,
          this.sizes$.canvas.height -
            this.sizes$.tile.height -
            this.config$.verticalMargin
        );

        layer.setPosition(
          { x: randomX, y: randomY },
          true,
          this.config$.shuffleTime
        );
      }
    });
  }

  // Shuffle all layers with 1 piece
  private shuffleTiles() {
    this.playSound("shuffle");
    if (this.layers$.find((layer) => layer.getTileCount() > 1) === undefined) {
      this.shuffleChaotic();
    } else {
      this.shuffleOrdered();
    }
  }

  getRandomPosition(): "top" | "left" | "right" | "bottom" {
    const random = Math.random() * 4;
    if (random < 1) {
      return "top";
    } else if (random > 1 && random < 2) {
      return "right";
    } else if (random > 2 && random < 3) {
      return "bottom";
    } else {
      return "left";
    }
  }

  private doesTileCollideWithTiles(tile: Rect, tiles: Layer[]) {
    tiles.forEach((layer) => {
      let tile2: Rect = {
        top: layer.getPosition().y,
        bottom: layer.getPosition().y + this.sizes$.tile.height,
        left: layer.getPosition().x,
        right: layer.getPosition().x + this.sizes$.tile.width,
      };
      if (this.doRectanglesCollide(tile, tile2)) {
        return true;
      }
    });
    return false;
  }

  private doRectanglesCollide(a: Rect, b: Rect) {
    let widthIsPositive = Math.min(a.right, b.right) > Math.max(a.left, b.left);
    let heightIsPositive =
      Math.min(a.bottom, b.bottom) > Math.max(a.top, b.top);

    return widthIsPositive && heightIsPositive;
  }

  private compareLayers(layer1: Layer, layer2: Layer) {
    if (layer1.getTileCount() < layer2.getTileCount()) {
      return -1;
    }
    if (layer1.getTileCount() > layer2.getTileCount()) {
      return 1;
    }
    return 0;
  }

  private getRandomNumber(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min);
  }

  private addProgressPoint() {
    if (this.state$.progress > 0) {
      const maxScore =
        this.state$.level.duration * this.config$.pointsPerMs * 1000;
      const percentScore = maxScore / 100;
      this.state$.score += percentScore;
      this.state$.progress = Math.max(0, this.state$.progress - 1); // Remove 1 percent;
    }
    this.playSound("timeBonus", false);
    this.updateScore();
  }

  public restart() {
    this.prepareGame();
    this.platform.ready();
  }

  private doEvents() {
    // While in init state, nothing can be painted yet.
    if (this.state$.paused) {
      this.nextFrame();
      // Do nothing
      return;
    }
    switch (this.state$.gameState) {
      case "init":
        this.nextFrame();
        return;

      // While playing. Update time and check for gameover and solved
      case "playing":
        this.updateRemainingTime();
        if (this.config$.useTime) {
          if (this.state$.progress == 0) {
            this.timedOut();
          }
        }
        if (this.checkWinCondition()) {
          this.puzzleSolved();
        }
        // if (this.state$.showBars === false) {
        //   if ((new Date().getTime() - this.state$.startTime.getTime()) > this.config$.shuffleTime ) {
        //     this.state$.showBars = true;

        //   }
        // }
        break;
      case "gamewon":
        // Keep adding points until progress reached 0
        if(this.waitForCenterAnimation){
          break;
        }
        if (this.state$.progress > 0) {
          this.addProgressPoint();
        } else {
          this.platform.sendTimeleft(0)
          this.levelCompleted();
        }
        break;
      case "prepareNext":
        break;
      case "ready":
        break;
      case "gameover":
        if (this.checkWinCondition()) {
          this.setGameState("playing");
          this.puzzleSolved();
        }
        break;
    }
    document.getElementById("remainingtime").innerHTML =
      this.state$.displayTime;
    // this.updateScore();
    this.canvas$.update();
    this.nextFrame();
  }

  private nextFrame() {
    window.setTimeout(() => {
      window.requestAnimationFrame(this.doEvents.bind(this));
    });
  }

  private timedOut() {
    if (this.state$.gameState !== "playing") {
      return;
    }
    this.setGameState("gameover");

    if (this.config.timeupMessage) {
      this.showPopup(this.config.timeupMessage, [
        {
          caption: this.config$.btnOkText,
          event: () => {
            this.sendScore();
            this.closePopup();
          },
        },
      ]);
    } else {
      setTimeout(() => {
        this.sendScore();
      }, this.config$.gameOverTime);
    }
  }

  private sendScore() {
    if (!this.state$.resultsend) {
      console.log("GAMEOVER");
      this.state$.resultsend = true;
      this.platform.gameover(Math.round(this.state$.score));
      // Next game timeout
      // setTimeout(() => {
      //   this.prepareGame();
      // }, 2000);
    }
  }

  private showPause() {
    if (this.state$.gameState !== "playing") {
      return;
    }
    this.pause();
    this.showPopup(this.config$.pauseText, [
      {
        caption: this.config$.btnOkText,
        event: () => {
          this.resume();
          this.playSound("btnClick");
        },
      },
    ]);
  }

  private confirmStop() {
    this.pause();
    this.showPopup(this.config$.stopMessage, [
      {
        caption: this.config$.btnYesText,
        event: () => {
          this.sendScore();
          this.playSound("btnClick");
        },
      },
      {
        caption: this.config$.btnNoText,
        event: () => {
          this.closePopup();
          this.resume();
          this.playSound("btnClick");
        },
      },
    ]);
  }

  waitForCenterAnimation: boolean = false;

  private puzzleSolved() {
    if (this.state$.gameState !== "playing") {
      return;
    }

    // Remove buttons
    document.getElementById("buttonBar").classList.remove("open");

    // Set state

    this.waitForCenterAnimation = true
    this.setGameState("gamewon");
    setTimeout(() => {
      this.waitForCenterAnimation = false
    }, this.config.solvedCenterTime)


    

    // Move final layer to center
    this.layers$[0].setPosition(
      {
        x:
          (this.sizes$.canvas.width - this.sizes$.puzzle.width) / 2 +
          this.config$.puzzleBoxPadding,
        y:
          this.config.verticalMargin +
          (this.sizes.canvas.height -
            this.config.verticalMargin * 2 -
            this.sizes.puzzle.height) /
            2 +
          this.config$.puzzleBoxPadding,
      },
      true,
      this.config$.solvedCenterTime
    );
  }

  /**
   * Called when all points are added to the score and the use has seen the message for some time.
   */
  private levelCompleted() {
    this.setGameState("prepareNext");
    if (this.state$.activeLevel === this.levels$.length - 1) {
      this.gameFinished();
    } else {
      this.levelFinished();
    }
  }

  /**
   * @returns
   */
  private levelFinished() {
    this.setGameState("prepareNext");

    this.platform.pause();
    this.playSound("levelFinished");
    this.showPopup(this.config.solvedMessage, [
      {
        caption: this.config.btnOkText,
        event: () => {
          this.closePopup();
          this.nextLevel();
          this.playSound("btnClick");
        },
      },
    ]);
  }

  private gameFinished() {
    this.playSound("gameFinished");

    this.showPopup(this.config.finishedMessage, [
      {
        caption: this.config.btnOkText,
        event: () => {
          this.sendScore();
          this.playSound("btnClick");
        },
      },
    ]);

    // setTimeout(() => {
    //   this.sendScore();
    // }, this.config$.gameOverTime);
  }

  private setGameState(newState: GameState) {
    this.state$.gameState = newState;
  }

  private updateScore() {
    // this.ELMScoreContainer.innerHTML = Math.round(this.state$.score)
    //   .toString()
    //   .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    this.platform.sendScore(Math.round(this.state$.score))
  }

  /**
   * Updates the relative progress of the duration (% of time left)
   * @returns
   */
  private updateRemainingTime(): void {
    if (this.state$.gameState !== "playing") {
      return;
    }
    const remainingTime =
      (new Date().getTime() - this.state$.startTime.getTime()) / 1000;
    const timeInPercentage =
      100 - (remainingTime / this.state$.level.duration) * 100;
    this.state$.progress = Math.max(0, timeInPercentage);
    this.state$.displayTime = this.formatTime(
      (new Date().getTime() - this.state$.startTime.getTime()) / 1000
    );
  }

  // Show the preview of the puzzle. Can never be called from playing state
  private preview() {
    this.start();

    // this.setGameState('preview');

    // Start the game after the preview timeout is reached
  }

  private start() {
    // if (this.state$.gameState !== 'ready') {
    //   console.error('Start called in wrongstate');
    //   return;
    // }

    setTimeout(() => {
      this.showPopup(this.config$.previewMessage, [
        {
          caption: this.config$.btnOkText,
          event: () => {
            this.platform.resume();
            this.platform.gamestarted();
            this.state$.showBars = true;
            document.getElementById("buttonBar").classList.add("open");
            document.getElementById("buttonBar").classList.add("open2");
            this.playSound("btnClick");
            this.shuffleTiles();
            (<HTMLElement>(
              document.getElementById("myProgress")
            )).style.visibility = "visible";
            (<HTMLElement>document.getElementById("myBar")).style.width =
              "100%";

            setTimeout(() => {
              // document.getElementById('buttonBar').classList.add('open');
              // document.getElementById('buttonBar').classList.add('open2');
              // Store starttime, and update state
              this.state$.startTime = new Date();
              this.setGameState("playing");

              // this.setGameState('playing');
            }, this.config$.shuffleTime);
            this.closePopup();
          },
        },
      ]);
    }, this.config.previewTime);

    // this.closePopup();
    // this.shuffleTiles();
    // Show bars when shuffle is done
  }

  private initToolbar() {
    // const buttonBar = document.getElementById('buttonBar'); // gamestate is ready

    if (this.config$.showShuffle) {
      const buttonShuffle = document.getElementById("btnShuffle"); // gamestate is ready
      buttonShuffle.style.display = "flex";
      buttonShuffle.innerHTML = this.config$.btnShuffleText;

      setEvent(buttonShuffle, () => {
        if (this.state.gameState === "playing") {
          this.playSound("btnClick");
          this.shuffleTiles();
        }
      });
    }

    if (this.config$.showStop) {
      const buttonStop = document.getElementById("btnStop"); // gamestate is ready
      buttonStop.innerHTML = this.config$.btnStopText;
      buttonStop.style.display = "flex";
      setEvent(buttonStop, () => {
        if (this.state.gameState === "playing") {
          this.playSound("btnClick");
          this.confirmStop();
        }
      });
    }

    if (this.config$.showTimeBox === false) {
      const timeBox = document.getElementById("remainingtime");
      timeBox.style.visibility = "hidden";
    }
  }

  private patchCss() {
    const css =
      this.style(
        "#score-container",
        this.conditionalStyle("color", this.config$.scoreFontColor) +
          this.conditionalStyle("font-size", this.config$.scoreFontSize, "px") +
          this.conditionalStyle("font-family", this.config$.scoreFont) +
          this.conditionalStyle("padding-top", this.config$.scoreOffsetY, "px")
      ) +
      this.style(
        ".btn",
        this.conditionalStyle("font-family", this.config$.btnFont) +
          this.conditionalStyle(
            "border-radius",
            this.config$.btnBorderRadius,
            "px"
          )
      ) +
      this.style(
        ".btn",
        this.conditionalStyle("color", this.config$.btnFontColor)
      ) +
      this.style(
        ".btn",
        this.conditionalStyle("font-size", this.config$.btnFontSize, "px")
      ) +
      this.style(
        ".btn",
        this.conditionalStyle("background-color", this.config$.btnColor)
      ) +
      this.style(
        "#popupMessage",
        this.conditionalStyle("font-family", this.config$.popupFont) +
          this.conditionalStyle("font-size", this.config$.popupFontSize, "px") +
          this.conditionalStyle(
            "border-radius",
            this.config$.popupBorderRadius,
            "px"
          ) +
          // this.conditionalStyle('opacity', this.config$.popupBackgroundOpacity) +
          this.conditionalStyle(
            "background-color",
            this.config$.popupBackgroundColor
          ) +
          this.conditionalStyle("color", this.config$.popupFontColor)
      ) +
      this.style(
        ".timebox",
        this.conditionalStyle("font-family", this.config$.timeBoxFont) +
          this.conditionalStyle(
            "border-radius",
            this.config$.timeBoxBorderRadius,
            "px"
          )
      ) +
      this.style(
        ".timebox",
        this.conditionalStyle("font-size", this.config$.timeBoxFontSize, "px") +
          this.conditionalStyle("color", this.config$.timeBoxFontColor) +
          this.conditionalStyle("background-color", this.config$.timeBoxColor)
      ) +
      this.style(
        ".modal",
        this.conditionalStyle("background-color", this.config$.overlayColor)
      );

    const sheet = document.createElement("style");
    sheet.innerHTML = css;
    console.log(css);
    document.body.appendChild(sheet);
  }
  private conditionalStyle(name: string, value, postfix = "") {
    if (!value) {
      value = 0;
    }
    return (
      `
     ` +
      name +
      ": " +
      value +
      postfix +
      ";"
    );
  }
  private style(name, value) {
    return (
      `
     ` +
      name +
      " { " +
      value +
      " } "
    );
  }
  private backgroundImage(filename: string) {
    const path = this.config$.path;
    // const cache = this.$enableCreatorMode ? '?' + Date.now() : '';
    const cache = ""; //'?' + Date.now();
    return "url('" + path + "/" + filename + cache + "')";
  }

  formatTime(time: number) {
    // Hours, minutes and seconds
    const hrs: number = ~~(time / 3600);
    const mins: number = ~~((time % 3600) / 60);
    const secs: number = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  sounds = {
    shuffleSound: "",
    levelFinishedSound: "",
    snapSound: "",
    pickUpSound: "",
    releaseSound: "",
    timeBonusSound: "",
    btnClickSound: "",
    gameFinishedSound: "",
    bounceSound: "",
  };

  playSound(soundKey: string, loop: boolean = false) {
    const config = this.config as any;
    if (config[soundKey + "Sound"])
      this.platform.playSound(config.path + "/" + config[soundKey + "Sound"]);
  }
}

// static debugData: Array<{ name: string, data: unknown }> = [];

// public setDebugInfo(name: string, data: unknown) {
//   Game.debugData = Game.debugData.filter(a => a.name !== name);
//   Game.debugData.push({ name, data });
//   document.getElementById('debugInfo').innerHTML =
//     Game.debugData.map(d => {
//       return '<div><pre>' + d.name + ':' + JSON.stringify(d.data, null, 2) + '</pre><div>';
//     }).join('');

// }
