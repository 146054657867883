import { PuzzleGame } from "./PuzzleGame";
import { Layer } from "./Layers";
import { IPosition, ISize } from "./interfaces";
import { RoundRect } from "./CanvasUtils";

export class GameCanvas {
	private canvas: HTMLCanvasElement;
	private context: CanvasRenderingContext2D;
	private gameObject: PuzzleGame;

	constructor(gameObject: PuzzleGame, canvas: HTMLCanvasElement) {
		this.gameObject = gameObject;
		this.canvas = canvas;
		this.context = canvas.getContext("2d");
	}

	public init() {
		(<HTMLElement>document.getElementById("myBar")).style.backgroundColor =
			this.gameObject.config.timeBarColor;
		(<HTMLElement>document.getElementById("myProgress")).style.top =
			60 + this.gameObject.config.timeBarOffsetY + "px";
		(<HTMLElement>document.getElementById("myProgress")).style.visibility =
			"hidden";

		(<HTMLElement>document.getElementById("myProgress")).style.borderColor =
			this.gameObject.config.timeBarBorderColor;
		(<HTMLElement>document.getElementById("myProgress")).style.borderWidth =
			this.gameObject.config.timeBarBorderWidth + "px";
		(<HTMLElement>(
			document.getElementById("myProgress")
		)).style.borderRadius =
			this.gameObject.config.timeBarBorderRadius + "px";

		(<HTMLElement>(
			document.getElementById("myBarBackground")
		)).style.backgroundColor =
			this.gameObject.config.timeBarBackgroundColor;
		(<HTMLElement>document.getElementById("myBar")).style.width = 100 + "%";
	}

	public update(): void {
		// teken balk
		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

		if (this.gameObject.state.gameState === "init") {
			return;
		}
		this.drawPlaceholder();
		if (this.gameObject.config.useTime && this.gameObject.state.showBars) {
			this.drawTimerBar();
		}
		this.drawLayers();
	}

	private drawLayers() {
		this.gameObject.layers.forEach((layer) => {
			layer.updateAnimation();
			if (layer.zoom) {
				this.context.drawImage(
					layer.getCanvas(),
					layer.getPosition().x * 0.98,
					layer.getPosition().y * 0.98,
					layer.getSize().width * 1.02,
					layer.getSize().height * 1.02
				);
			} else {
				this.context.drawImage(
					layer.getCanvas(),
					layer.getPosition().x,
					layer.getPosition().y,
					layer.getSize().width,
					layer.getSize().height
				);
			}
		});
	}

	private drawPlaceholder() {
		// Background
		this.context.fillStyle = this.gameObject.config.puzzleBoxColor;
		this.context.globalAlpha = this.gameObject.config.puzzleBoxOpacity;

		const puzzleSize = { ...this.gameObject.sizes.puzzle };
		puzzleSize.width = Math.floor(puzzleSize.width);
		puzzleSize.height = Math.floor(puzzleSize.height);

		const puzzlePosition = {
			x: (this.gameObject.sizes.canvas.width - puzzleSize.width) / 2,
			y:
				this.gameObject.config.verticalMargin +
				(this.gameObject.sizes.canvas.height -
					this.gameObject.config.verticalMargin -
					this.gameObject.config.verticalMargin -
					puzzleSize.height) /
					2,
		};
		// puzzlePosition.x = puzzlePosition.x * 1.1;
		// puzzlePosition.y = puzzlePosition.y * 1.1;

		RoundRect(
			this.context,
			puzzlePosition,
			puzzleSize,
			this.gameObject.config.puzzleBoxBorderRadius,
			true,
			false
		);
		this.context.globalAlpha = 1.0;
	}

	private drawTimerBar() {
		const timePercentage = this.gameObject.state.progress;
		// Progress
		if (timePercentage < 100) {
			// (<HTMLElement>document.getElementById("myBar")).style.width =
			// 	timePercentage + "%";
			this.gameObject.platform.sendTimeleft(timePercentage, (this.gameObject.state.gameState != "playing" && !this.gameObject.waitForCenterAnimation))
		}
	}

	public checkCoordinates(position: IPosition): Layer | null {
		for (let x = this.gameObject.layers.length - 1; x >= 0; x--) {
			if (this.gameObject.layers[x].coversPosition(position)) {
				return this.gameObject.layers[x];
			}
		}
		return null;
	}
}
