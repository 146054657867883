class IPosition {
  public x: number;
  public y: number;
  constructor(x?: number, y?: number) {
    this.x = x;
    this.y = y;
  }
}

class ISize {
  width: number;
  height: number;
  constructor(width?: number, height?: number) {
    this.width = width;
    this.height = height;
  }
}


interface ITileSize {
  height: number;
  width: number;
}

interface IBaseposition {
  x: number;
  y: number;
}

export {
  IPosition,
  ISize,
  ITileSize,
  IBaseposition
};

export type GameState = 'init' | 'ready' | 'playing' | 'gamewon' | 'prepareNext' | 'gameover' | 'preview';

export interface ILevel {
  image: string;
  rows: number;
  cols: number;
  duration: number;
}

export interface IGameState {
  gameState: GameState,
  score: number,
  dragging: boolean,
  startTime: Date,
  activeLevel: number
  progress: number;
  displayTime: string;
  level: ILevel;
  paused: boolean;
  pauseTime: Date | null;
  showBars: boolean;
  resultsend: boolean;
}

export interface IGameSizes {
  canvas: ISize;
  puzzle: ISize;
  tile: ISize;
  sourceTile: ISize;
}

export interface IActionButton {
  caption: string;
  event: EventListenerOrEventListenerObject;
}

export interface IGameConfig {
  path: string;

  pointsPerTile: number,
  useTime: boolean;
  pointsPerMs: number,
  snapMarge: number,

  scoreFontColor: string;

  puzzleSize: number;
  verticalMargin: number;
  horizontalMargin: number
  puzzleBoxColor: string;
  puzzleBoxOpacity: number;
  puzzleBoxBorderRadius: number;
  puzzleBoxPadding: number;


  btnColor: string;
  btnBorderRadius: number;
  btnFont: string;
  btnFontColor: string;
  btnFontSize: number;

  showShuffle: boolean,
  showStop: boolean,

  popupBackgroundColor: string;
  popupBorderRadius: number;
  popupFont: string;
  popupFontColor: string;
  popupFontSize: number;

  overlayColor: string;

  showTimeBox: boolean,
  timeBoxColor: string;
  timeBoxFont: string;
  timeBoxFontColor: string;
  timeBoxFontSize: number;
  timeBoxBorderRadius: number;


  previewTime: number;   // 6000
  shuffleTime: number;
  solvedCenterTime: number; // 500

  nextLevelTime: number;  // niet

  mergeBounceTime: number;
  bounceTime: number;
  gameOverTime: number;

  scoreFontSize: number,
  scoreFont: string;
  scoreOffsetY: number,

  levels: string;



  shuffleSound: string;
  levelFinishedSound: string;
  gameFinishedSound: string;
  snapSound: string;
  releaseSound: string;
  bounceSound: string;
  btnClickSound: string;
  pickUpSound: string;
  timeBonusSound: string;

  timeBarBorderColor: string;
  timeBarColor: string;
  timeBarBackgroundColor: string;
  timeBarBorderRadius: number;
  timeBarBorderWidth: number;
  timeBarOffsetY: number;

  solvedMessage: string;
  timeupMessage: string;
  previewMessage: string;
  finishedMessage: string;
  stopMessage: string;


  btnStopText: string;
  btnShuffleText: string;
  btnYesText: string;
  btnNoText: string;
  btnOkText: string;


  pauseText: string
}