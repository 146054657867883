'use strict';
import {  IGameConfig } from './interfaces';

const DefaultConfig: IGameConfig = {
  path: 'https://gamecdn.playenwin.com/wietse/puzzel/default',

  levels: 'foto.png,1x3,10;default.jpg,1x2,20',
  pointsPerTile: 100,
  useTime: false,
  pointsPerMs: 1,
  snapMarge: 50,

  scoreOffsetY: 0,

  puzzleSize: 90,
  verticalMargin: 80,
  horizontalMargin: 10,
  puzzleBoxColor: '#fff',
  puzzleBoxOpacity: 0.4,
  puzzleBoxBorderRadius: 50,
  puzzleBoxPadding: 10,


  btnColor: '#483B6F',
  btnFont: "Acme",
  btnFontSize: 50,
  btnFontColor: '#FFFFFF',
  btnBorderRadius: 10,

  showTimeBox: false,
  timeBoxFont: "Bonbon",
  timeBoxBorderRadius: 20,
  timeBoxColor: "black",
  timeBoxFontColor: "white",
  timeBoxFontSize: 20,

  popupBackgroundColor: '#ffffffcc',
  popupBorderRadius: 30,
  popupFont: 'Arial',
  popupFontSize: 20,
  popupFontColor: 'black',

  scoreFontSize: 30,
  scoreFontColor: '#FFF',
  scoreFont: "Acme",

  timeBarBorderColor: '#1d3544',
  timeBarColor: '#ed8b00',
  timeBarBackgroundColor: '#fff',
  timeBarBorderRadius: 0,
  timeBarBorderWidth: 0,
  timeBarOffsetY: 0,

  shuffleTime: 500,
  mergeBounceTime: 1000,
  bounceTime: 250,
  gameOverTime: 4000,
  previewTime: 2000,
  nextLevelTime: 1000,












  solvedMessage: 'Puzzel opgelost goed gedaan!',
  timeupMessage: 'Time\'s up !',
  previewMessage: 'Get ready',
  showShuffle: true,
  showStop: true,


  btnYesText: 'Ja',
  btnNoText: 'Nee',
  btnOkText: 'Ok',

  btnShuffleText: 'Shuffle',
  btnStopText: 'Stop',
  finishedMessage: 'Je hebt alle puzzel opgelost!',

  overlayColor: '#00000066',
  pauseText: 'Pauze',

  solvedCenterTime: 4000,
  stopMessage: 'Weet je zeker dat je wilt stoppen?',

  shuffleSound: "",
  levelFinishedSound: "",
  snapSound:"",
  pickUpSound: "",
  releaseSound: "",
  timeBonusSound: "",
  btnClickSound: "",
  gameFinishedSound: "",
  bounceSound: "",


};

export { DefaultConfig };

