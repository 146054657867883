import { IPosition, ISize } from './interfaces';

export interface IRadius {
    tl: number, tr: number, br: number, bl: number
}

export type Radius = IRadius | number | string;
/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param [radius.tl = 0] Top left
 * @param [radius.tr = 0] Top right
 * @param [radius.br = 0] Bottom right
 * @param [radius.bl = 0] Bottom left
 * @param [fill = false] Whether to fill the rectangle.
 * @param [stroke = true] Whether to stroke the rectangle.
 */
export function RoundRect(ctx: CanvasRenderingContext2D, position: IPosition, size: ISize, inputRadius: Radius = 5, fill = false, stroke = true): void {
  const x = position.x;
  const y = position.y;
  const w = size.width;
  const h = size.height;
  let radius: IRadius;
  let radiusParts: Array<string>;

  switch (typeof inputRadius) {
    case 'number':
      radius = { tl: inputRadius, tr: inputRadius, br: inputRadius, bl: inputRadius };
      break;
    case 'string':
      radiusParts = inputRadius.trim().split(' ');
      if (radiusParts.length === 2) {
        radius = { tl: +radiusParts[0], tr: +radiusParts[0], br: +radiusParts[1], bl: +radiusParts[1] };
      }
      break;
    case 'object':
      radius = inputRadius;
      break;
  }
  const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
  for (const side in defaultRadius) {
    radius[side] = radius[side] || defaultRadius[side];
  }
  let r = radius.bl;
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  ctx.beginPath();
  ctx.moveTo(x+r, y);
  ctx.arcTo(x+w, y,   x+w, y+h, r);
  ctx.arcTo(x+w, y+h, x,   y+h, r);
  ctx.arcTo(x,   y+h, x,   y,   r);
  ctx.arcTo(x,   y,   x+w, y,   r);
  ctx.closePath();
  // ctx.stroke();
  // return;
  // ctx.beginPath();
  // ctx.moveTo(x + radius.tl, y);
  // ctx.lineTo(x + width - radius.tr, y);
  // ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  // ctx.lineTo(x + width, y + height - radius.br);
  // ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  // ctx.lineTo(x + radius.bl, y + height);
  // ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  // ctx.lineTo(x, y + radius.tl);
  // ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  // ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }

}