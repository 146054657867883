import { Tile } from "./Tile";
import { PuzzleGame } from "./PuzzleGame";
import { IPosition, ISize } from "./interfaces";

interface ILayerState {
	animation: {
		startPos: IPosition;
		targetPos: IPosition;
		startTime: Date;
		active: boolean;
		duration: number;
	};
	zoom: boolean;
}

export class Layer {
	private tiles$: Tile[] = [];
	private game: PuzzleGame;
	private canvas: HTMLCanvasElement;
	private ctx: CanvasRenderingContext2D;
	private size: ISize = { height: 0, width: 0 };
	private position: IPosition = { x: 0, y: 0 };
	private state: ILayerState;
	constructor(tile: Tile, game: PuzzleGame) {
		this.state = {
			animation: {
				active: false,
				startPos: { x: 0, y: 0 },
				startTime: new Date(),
				targetPos: { x: 0, y: 0 },
				duration: 0,
			},
			zoom: false,
		};

		const leftMargin =
			(game.sizes.canvas.width - game.sizes.puzzle.width) / 2 +
			game.config.puzzleBoxPadding;
		const topMargin =
			game.config.verticalMargin +
			(game.sizes.canvas.height -
				game.sizes.puzzle.height -
				game.config.verticalMargin -
				game.config.verticalMargin) /
				2 +
			game.config.puzzleBoxPadding;

		this.position = {
			x: (tile.col - 1) * game.sizes.tile.width + leftMargin,
			y: (tile.row - 1) * game.sizes.tile.height + topMargin,
		};
		this.game = game;
		this.addTile(tile);

		this.canvas = document.createElement("canvas");
		this.ctx = this.canvas.getContext("2d");

		this.updateLayerCanvas();
	}

	public trySnap(): Layer | null {
		for (let x = 0; x < this.tiles$.length; x++) {
			const tile = this.tiles$[x];
			const result = tile.checkSnap();
			if (result.length > 0) {
				return result.shift().getLayer();
			}
		}
		return null;
	}
	public checkPosition(duration: number): void {
		const canvasSize = this.game.sizes.canvas;
		console.log(this.size);
		const maxX = Math.floor(
			canvasSize.width -
				this.size.width -
				this.game.config.horizontalMargin
		);
		const maxY = Math.floor(
			canvasSize.height -
				this.size.height -
				this.game.config.verticalMargin
		);
		if (
			this.position.x < this.game.config.horizontalMargin ||
			this.position.y < this.game.config.verticalMargin ||
			this.position.x > maxX ||
			this.position.y > maxY
		) {
			this.state.animation.startPos = this.position;
			this.state.animation.startTime = new Date();
			this.state.animation.targetPos = {
				x: Math.min(
					maxX,
					Math.max(this.game.config.horizontalMargin, this.position.x)
				),
				y: Math.min(
					maxY,
					Math.max(this.game.config.verticalMargin, this.position.y)
				),
			};
			console.log(this.state.animation.targetPos);
			this.state.animation.active = true;
			this.state.animation.duration = duration;
			this.game.playSound(this.game.config.bounceSound);
		}
	}

	public mergeLayer(droppedLayer: Layer): void {
		// Get all row numbers in layer
		const rowNumbers = this.tiles$.map((tile) => tile.row);
		const colNumbers = this.tiles$.map((tile) => tile.col);

		// Find top and left tile position
		let leftCol = Math.min(...colNumbers);
		let topRow = Math.min(...rowNumbers);

		// Add all tiles from dragged layer to this layer
		droppedLayer.tiles$.forEach((tile) => {
			// Layer extended above, move y of layer
			if (tile.row < topRow) {
				this.position.y =
					this.position.y -
					(topRow - tile.row) * this.game.sizes.tile.height;
				topRow = tile.row;
			}
			// Layer extended to left, move x of layer
			if (tile.col < leftCol) {
				this.position.x =
					this.position.x -
					(leftCol - tile.col) * this.game.sizes.tile.width;
				leftCol = tile.col;
			}

			// Register the tile
			this.addTile(tile);
		});
		this.game.removeLayer(droppedLayer);

		this.updateLayerCanvas();
	}

	public coversPosition(position: IPosition): boolean {
		if (position.x < this.position.x) {
			return false;
		}
		if (position.y < this.position.y) {
			return false;
		}
		if (position.x > this.position.x + this.size.width) {
			return false;
		}
		if (position.y > this.position.y + this.size.height) {
			return false;
		}
		// whitin layer.
		// relative position:
		let found = false;
		const relPos = new IPosition(
			position.x - this.position.x,
			position.y - this.position.y
		);
		this.tiles$.forEach((tile) => {
			if (relPos.x < tile.position.x) {
				return;
			}
			if (relPos.y < tile.position.y) {
				return;
			}
			if (relPos.x > tile.position.x + this.game.sizes.tile.width) {
				return;
			}
			if (relPos.y > tile.position.y + this.game.sizes.tile.height) {
				return;
			}
			found = true;
		});
		return found;
	}

	public getTileCount(): number {
		return this.tiles$.length;
	}

	public getCanvas(): HTMLCanvasElement {
		return this.canvas;
	}

	public getSize(): ISize {
		return this.size;
	}

	public getPosition(): IPosition {
		return this.position;
	}

	public updateAnimation(): void {
		if (!this.state.animation.active) {
			return;
		}
		const animation = this.state.animation;

		const timeDiff = new Date().getTime() - animation.startTime.getTime();
		// Target reached
		if (timeDiff > animation.duration) {
			this.position = animation.targetPos;
			this.state.animation.active = false;
			return;
		}
		// No duration
		if (animation.duration <= 0) {
			this.position = animation.targetPos;
			this.state.animation.active = false;
			return;
		}
		const progress = timeDiff / animation.duration; // x%
		const dX = animation.startPos.x - animation.targetPos.x;
		const dY = animation.startPos.y - animation.targetPos.y;
		const mX = dX * progress;
		const mY = dY * progress;
		const newX = this.easeInOutQuad(
			timeDiff,
			animation.startPos.x,
			animation.targetPos.x - animation.startPos.x,
			animation.duration
		);
		const newY = this.easeInOutQuad(
			timeDiff,
			animation.startPos.y,
			animation.targetPos.y - animation.startPos.y,
			animation.duration
		);
		// this.position = { x: animation.startPos.x - mX, y: animation.startPos.y - mY };
		this.position = { x: newX, y: newY };
	}

	// formula     http://easings.net/
	// description https://stackoverflow.com/questions/8316882/what-is-an-easing-function
	// x: percent
	// t: current time,
	// b: beginning value,
	// c: change in value,
	// d: duration
	easeInOutQuad(t: number, b: number, c: number, d: number): number {
		if ((t /= d / 2) < 1) {
			return (c / 2) * t * t + b;
		} else {
			return (-c / 2) * (--t * (t - 2) - 1) + b;
		}
	}

	public setPosition(
		newPosition: IPosition,
		animated: boolean,
		duration?: number
	): void {
		if (animated) {
			if (!this.state.animation.active) {
				this.state.animation.startPos = this.position;
			}
			this.state.animation.startTime = new Date();
			this.state.animation.active = true;
			this.state.animation.targetPos = newPosition;
			this.state.animation.duration = duration;
		} else {
			this.state.animation.active = false;
			this.position = newPosition;
		}
	}

	public setLayerSize(width: number, height: number): void {
		this.size.width = width;
		this.size.height = height;
	}

	/***************************
	 * Private functions
	 ***************************/
	/**
	 *
	 * @param tile
	 */
	private addTile(tile: Tile): void {
		this.tiles$.push(tile);
		tile.setLayer(this);
	}

	private updateLayerCanvas() {
		const rowNumbers = this.tiles$.map((tile) => tile.row);
		const colNumbers = this.tiles$.map((tile) => tile.col);
		const leftCol = Math.min(...colNumbers);
		const rightCol = Math.max(...colNumbers);
		const topRow = Math.min(...rowNumbers);
		const bottomRow = Math.max(...rowNumbers);
		const tileSize = this.game.sizes.tile;

		// Update layer size based on rows and cols in the layer
		this.size = {
			width: (rightCol - leftCol + 1) * tileSize.width,
			height: (bottomRow - topRow + 1) * tileSize.height,
		};

		// Update tile positions whitin this layer
		this.tiles$.forEach((tile) => {
			tile.setTilePosition({
				x: (tile.col - leftCol) * tileSize.width,
				y: (tile.row - topRow) * tileSize.height,
			});
		});

		// Update canvas size and place all tiles
		this.canvas.width = this.size.width;
		this.canvas.height = this.size.height;

		// Clean layer
		this.ctx.clearRect(0, 0, this.size.width, this.size.height);

		// Add tiles
		this.tiles$.forEach((tile) => {
			tile.drawTile(this.canvas);
		});
	}

	public updateSizes(): void {
		this.tiles$.forEach((t) => {
			t.repaint();
		});
		this.updateLayerCanvas();
	}

	get zoom(): boolean {
		return this.state.zoom;
	}

	public setZoom(value: boolean): void {
		this.state.zoom = value;
	}
}
