
export function setEvent(element: Element | Document, action: EventListenerOrEventListenerObject, touchAction?: EventListenerOrEventListenerObject): void {
  if ('ontouchstart' in window) {
    if (touchAction) {
      element.addEventListener('touchstart', touchAction, { passive: false });
    } else {
      element.addEventListener('touchstart', action, { passive: false });
    }
  } else {
    element.addEventListener('mousedown', action, { passive: false });
  }
}

export type DragEventType = 'down' | 'up' | 'move' | 'out';
const DragEventTypeNames = {
  'down': { t: 'touchstart', m: 'mousedown' },
  'up': { t:'touchend', m: 'mouseup' },
  'move': { t:'touchmove', m: 'mousemove' },
  'out': { t:'mousout', m: 'mouseout' }
};

export interface DragEventCallbackFunction {
  (x: number, y: number): void
}

export function SetDragEvent(element: Element | Document, type: DragEventType, handler: DragEventCallbackFunction): void {
  const touchEventName = DragEventTypeNames[type].t;
  const clickEventName = DragEventTypeNames[type].m;
  const testname =  'on' + touchEventName;
  if (testname in window) {
    element.addEventListener(touchEventName, (e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      handler(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    }, { passive: false });
  }
  else {
    element.addEventListener(clickEventName, (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      handler(e.clientX, e.clientY);
    }, { passive: false });
  }
}

function debugmsg(test: string) {
  document.getElementById('debugInfo').innerHTML = document.getElementById('debugInfo').innerHTML + '<br>' + test;
}

export function disableTouch(): void {
  // Register gesturestart to prevent complex events
  document.addEventListener('gesturestart', (e) => {
    e.preventDefault();
    registerEvent('gesturestart');
  }, { passive: false });

  // Handle default mouse down OR touch start to prevent pinch zoom etc.
  setEvent(document, (e) => {
    registerEvent('prevent ' + e.type);
    e.preventDefault();
  });

  // Catch move events
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    window.document.addEventListener('touchmove',
      e => {
        registerEvent('ios touch move');
        e.preventDefault();
      }, { passive: false }
    );
  }
}

function registerEvent(name: string) {

  if (document.getElementById('messages')) {
    document.getElementById('messages').innerHTML = name + '<br>' + document.getElementById('messages').innerHTML;
  }
  // if (top.document.getElementById('messages')) {
  //   top.document.getElementById('messages').innerHTML = name + '<br>' + top.document.getElementById('messages').innerHTML;
  // }
}
