import { IGameConfig } from './interfaces';
import { DefaultConfig } from './DefaultConfig';

var WebFont = require("webfontloader");


export class Configurator {
  private config: IGameConfig;

  onlyUnique(value: string, index: number, array: any[]) {
    return array.indexOf(value) === index;
  }


  getWebFonts(config: IGameConfig){
    const fonts: string[] = [config.popupFont, config.scoreFont, config.btnFont, config.timeBoxFont];
    return fonts.filter(this.onlyUnique);
  }

  public loadConfig(): IGameConfig {
    const config = this.getDefault();
    this.patch(config);
    this.printConfig(config);
    WebFont.load({
      google: {
        families: this.getWebFonts(config)
      }
  });

    return config;
  }

  private getDefault(): IGameConfig {
    const urlParams = new URLSearchParams(window.location.search);
    switch (urlParams.get('v')) {
      // case '1':
      //   this.config = GameConfig1;
      //   break;
      // case '2':
      //   this.config = GameConfig2;
      //   break;
      default:
        return DefaultConfig;
    }
  }

  patch(config: IGameConfig): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.get('s')) {
      return;
    }

    const settings = atob(urlParams.get('s')).split('\n')
      .filter(s => s.trim() !== '')
      .filter(s => s.substr(0, 1) !== '#')
      .map(s => {
        return { name: s.split('=')[0].trim(), value: s.split('=')[1].trim() };
      });

    settings.forEach(s => {
      if (!isNaN(+s.value)) {
        config[s.name] = +s.value;
      } else if (s.value === 'true') {
        config[s.name] = true;
      } else if (s.value === 'false') {
        config[s.name] = false;
      } else {
        config[s.name] = s.value;
      }
    });

    console.log(config);
  }

   printConfig(config: any){
    let string = "";
    for (const [key, value] of Object.entries(config)) {
      string += key + "=" + value + "\n";
    }
    console.log(string);
  }
}
