/**
 * Add drag tracking functionality for single toch point
 * @Author Jeroen van der Molen / AuguSoft
 */

// interface for registered event handlers
export interface DragEventCallbackFunction {
   (x: number, y: number): void;
}

export class DragHandler {
  private elm: Element;
  private enableTouch = false;
  private startHandler: DragEventCallbackFunction;
  private moveHandler: DragEventCallbackFunction;
  private dropHandler: DragEventCallbackFunction;
  private firstTouch: number | null = null;

  constructor(elm: Element, startHandler: DragEventCallbackFunction, moveHandler: DragEventCallbackFunction, dropHandler: DragEventCallbackFunction) {
    this.elm = elm;
    this.enableTouch = 'ontouchstart' in window;
    this.startHandler = startHandler;
    this.moveHandler = moveHandler;
    this.dropHandler = dropHandler;
    if (this.enableTouch) {
      this.registerTouchEvents();
    } else {
      this.registerMouseEvents();
    }
  }

  // Register all touch event
  private registerTouchEvents() {
    this.elm.addEventListener('touchstart', this.touchstart.bind(this));
    this.elm.addEventListener('touchmove', this.touchmove.bind(this));
    this.elm.addEventListener('touchend', this.touchend.bind(this));
  }

  /**
   * Simple mouse events
   */
  private registerMouseEvents() {
    this.elm.addEventListener('mousedown', this.mousedown.bind(this));
    this.elm.addEventListener('mousemove', this.mousemove.bind(this));
    this.elm.addEventListener('mouseup', this.mouseup.bind(this));
    this.elm.addEventListener('mouseout', this.mouseup.bind(this));
  }

  private touchstart(e: TouchEvent) {
    e.preventDefault;
    e.stopPropagation();

    // Active first touchpoint and still exists, ignore start event
    if ((this.firstTouch !== null) && this.findTouch(e.touches, this.firstTouch)) {
      return;
    }

    if (this.firstTouch !== null) {
      // First touch is set, but not found anymore
      console.error('active touch point does not exist anymore');
    }

    // Register first touchpoint
    this.firstTouch = e.changedTouches[0].identifier;
    return this.startHandler(e.changedTouches[0].clientX,e.changedTouches[0].clientY);
  }

  private touchmove(e: TouchEvent) {
    e.stopPropagation();
    e.stopPropagation();

    // If no first touch is preset, just ignore this call
    if (this.firstTouch === null) {
      return;
    }

    // If first touch is not found in changes, ignore this call
    const activeTouch = this.findTouch(e.changedTouches, this.firstTouch);
    if (activeTouch === null) {
      return;
    }

    return this.moveHandler(activeTouch.clientX, activeTouch.clientY);
  }

  private touchend(e: TouchEvent) {
    e.stopPropagation();
    e.stopPropagation();

    // If no first touch is preset, just ignore this call
    if (this.firstTouch === null) {
      return;
    }

    // If first touch is not found in changes, ignore this call
    const activeTouch = this.findTouch(e.changedTouches, this.firstTouch);
    if (activeTouch === null) {
      return;
    }

    // The registered touchpoint is ended, unregister touchpoint and send position
    this.firstTouch = null;
    return this.dropHandler(activeTouch.clientX, activeTouch.clientY);
  }

 
  private mousedown(e: MouseEvent) {
    e.preventDefault;
    e.stopPropagation();
    this.startHandler(e.clientX, e.clientY);
  }
  private mousemove(e: MouseEvent) {
    e.preventDefault;
    e.stopPropagation();
    this.moveHandler(e.clientX, e.clientY);
  }
  private mouseup(e: MouseEvent) {
    e.preventDefault;
    e.stopPropagation();
    this.dropHandler(e.clientX, e.clientY);
  }

  // Find touch event based on ID
  private findTouch(touchList: TouchList, id: number): Touch | null {
    for (let i = 0; i < touchList.length; i++) {
      if (touchList[i].identifier === id) {
        return touchList[i];
      }
    }
    return null;
  }
}
