import { PuzzleGame } from "./PuzzleGame";
import { IPosition, ISize } from "./interfaces";
import { Layer } from "./Layers";

interface IRelatedTile {
	row: number;
	col: number;
	position: "left" | "right" | "above" | "below";
}

export class Tile {
	private canvas: HTMLCanvasElement;
	private position$: IPosition = { x: 0, y: 0 };
	private snappableTiles: Array<IRelatedTile> = [];
	public row: number;
	public col: number;
	private gameObject: PuzzleGame;
	g;
	private layer: Layer;

	constructor(gameObject: PuzzleGame, col: number, row: number) {
		this.gameObject = gameObject;
		this.row = row;
		this.col = col;

		// Register all tiles around this one
		this.snappableTiles = [];
		if (this.row > 1) {
			this.snappableTiles.push({
				row: row - 1,
				col: col,
				position: "above",
			});
		}
		if (this.row < this.gameObject.state.level.rows) {
			this.snappableTiles.push({
				row: row + 1,
				col: col,
				position: "below",
			});
		}
		if (this.col > 1) {
			this.snappableTiles.push({
				row: row,
				col: col - 1,
				position: "left",
			});
		}
		if (this.col < this.gameObject.state.level.cols) {
			this.snappableTiles.push({
				row: row,
				col: col + 1,
				position: "right",
			});
		}

		this.canvas = document.createElement("canvas");
		this.repaint();
	}

	repaint(): void {
		this.canvas.width = this.gameObject.sizes.tile.width;
		this.canvas.height = this.gameObject.sizes.tile.height;
		const context = this.canvas.getContext("2d");

		if (context)
			context.drawImage(
				this.gameObject.getImage(),
				(this.col - 1) * this.gameObject.sizes.sourceTile.width,
				(this.row - 1) * this.gameObject.sizes.sourceTile.height,
				this.gameObject.sizes.sourceTile.width,
				this.gameObject.sizes.sourceTile.height,
				0,
				0,
				this.canvas.width,
				this.canvas.height
			);
	}

	public checkSnap(): Tile[] {
		const myPos = this.getCanvasPosition();
		const foundSnapTiles: Tile[] = [];
		this.snappableTiles.forEach((tile) => {
			const p = this.gameObject.getTile(tile.row, tile.col);
			if (p.getLayer() === this.getLayer()) {
				return;
			}
			const snapMarge = this.gameObject.config.snapMarge;
			const tilePos = p.getCanvasPosition();
			const targetYWithMarge =
				tilePos.y + this.gameObject.sizes.tile.height + snapMarge;
			const targetXWithMarge =
				tilePos.x + this.gameObject.sizes.tile.width + snapMarge;
			const myPosX =
				myPos.x + this.gameObject.sizes.tile.width + snapMarge;
			const myPosY =
				myPos.y + this.gameObject.sizes.tile.height + snapMarge;
			switch (tile.position) {
				case "above":
					if (
						targetYWithMarge - myPos.y >= 0 - snapMarge &&
						targetYWithMarge - myPos.y <= snapMarge &&
						tilePos.x - myPos.x <= snapMarge &&
						myPosX - targetXWithMarge <= snapMarge
					) {
						foundSnapTiles.push(p);
						console.log("ik wil naar boven snappen!");
					}
					break;
				case "below":
					if (
						myPos.y +
							this.gameObject.sizes.tile.height -
							tilePos.y <=
							snapMarge &&
						myPos.y +
							this.gameObject.sizes.tile.height -
							tilePos.y >=
							-snapMarge &&
						tilePos.x - myPos.x <= snapMarge &&
						tilePos.x - myPos.x >= -snapMarge
					) {
						foundSnapTiles.push(p);
						console.log("ik wil naar onder snappen!");
					}
					break;
				case "left":
					if (
						targetXWithMarge - myPos.x <= snapMarge &&
						targetXWithMarge - myPos.x >= 0 - snapMarge &&
						tilePos.y - myPos.y <= snapMarge &&
						myPosY - targetYWithMarge <= snapMarge
					) {
						foundSnapTiles.push(p);
						console.log("ik wil naar links snappen!");
					}
					break;
				case "right":
					if (
						myPosX - tilePos.x - snapMarge >= -snapMarge &&
						myPosX - tilePos.x - snapMarge <= snapMarge &&
						tilePos.y - myPos.y <= snapMarge &&
						myPosY - targetYWithMarge <= snapMarge
					) {
						foundSnapTiles.push(p);
						console.log("ik wil naar rechts snappen!");
					}
					break;
			}
		});
		return foundSnapTiles;
	}

	public getCanvasPosition(): IPosition {
		return {
			x: this.position$.x + this.layer.getPosition().x,
			y: this.position$.y + this.layer.getPosition().y,
		};
	}

	//connected tiles
	public getAdjacentTiles(): Array<IRelatedTile> {
		return this.snappableTiles;
	}

	// Place this tile on a canvas
	public drawTile(targetCanvas: HTMLCanvasElement): void {
		// this.clearTile(canvas);
		const ctx = targetCanvas.getContext("2d");
		ctx.drawImage(
			this.canvas,
			this.position$.x,
			this.position$.y,
			this.gameObject.sizes.tile.width,
			this.gameObject.sizes.tile.height
		);
	}

	public clearTile(canvas: HTMLCanvasElement): void {
		const context = canvas.getContext("2d");
		context.clearRect(
			this.position$.x,
			this.position$.y,
			this.gameObject.sizes.tile.width,
			this.gameObject.sizes.tile.height
		);
	}

	public setLayer(layer: Layer): void {
		this.layer = layer;
	}

	public getLayer(): Layer {
		return this.layer;
	}

	public getCanvas(): HTMLCanvasElement {
		return this.canvas;
	}

	public getTilePosition(): IPosition {
		return this.position$;
	}

	get position(): IPosition {
		return this.position$;
	}

	public setTilePosition(newPosition: IPosition): void {
		this.position$ = newPosition;
	}
}
